import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import { HTMLContent } from "../components/Content";
import { FormattedMessage } from "gatsby-plugin-intl";

export const AboutPageTemplate = ({}) => {
  return (
    <div className="container heading-container">
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div className="section">
            <div className="container heading-container">
              <h1
                className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                style={{
                  lineHeight: "1",
                  padding: "0.25em"
                }}
              >
                <FormattedMessage id="about" />
              </h1>
              <span className="sub-heading">
                <FormattedMessage id="about_description" />
              </span>
            </div>
            <div className="columns">
              <div className="column is-6 container heading-container">
                <h1
                  className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-5-widescreen"
                  style={{
                    lineHeight: "1",
                    padding: "0.25em"
                  }}
                >
                  Dalius Pletniovas
                </h1>
                <span className="sub-heading">
                  <FormattedMessage id="dalius_about" />
                </span>
              </div>

              <div className="column is-6 container heading-container">
                <h1
                  className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-5-widescreen"
                  style={{
                    lineHeight: "1",
                    padding: "0.25em"
                  }}
                >
                  Julius Janušauskas
                </h1>
                <span className="sub-heading">
                  <FormattedMessage id="julius_about" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

AboutPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string,
  contentComponent: PropTypes.func
};

const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

AboutPage.propTypes = {
  data: PropTypes.object.isRequired
};

export default AboutPage;

export const aboutPageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
